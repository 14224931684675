import React from "react";
import "./press-release.css";
import ApplicationHelmet from "../components/Helmet";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
import { navigate } from "gatsby";
import Footer from "../shared/Footer";
import StaticLayout from "./StaticLayout";

const PressRelease = () => {
  return (
    <ApplicationHelmet>
      <StaticLayout isNotHomePage={false}>
        <div className="bread-wrapper">
          <Container>
            <Breadcrumb>
              <Breadcrumb.Item onClick={() => navigate("/")}>
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Press Release</Breadcrumb.Item>
            </Breadcrumb>
          </Container>
        </div>
        <div className="py-5 dark-bg">
          <Container>
            <Row>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://cdn.i-scmp.com/sites/default/files/styles/1200x800/public/d8/images/canvas/2024/03/22/4927f8fb-27df-49c4-a0ae-e76c2fb5c55d_9cdc5276.jpg?itok=-OMnSss3&v=1711110215"
                    />
                  </figure>
                  <small>South China Morning Post</small>
                  <a
                    rel="noreferrer"
                    href="https://www.scmp.com/week-asia/economics/article/3256423/india-comes-chinas-manufacturing-crown-supply-chains-slowly-shift?campaign=3256423&module=perpetual_scroll_0&pgtype=article"
                    target="_blank"
                  >
                    <h3>
                      India comes for China&apos;s manufacturing crown as supply
                      chains slowly shift
                    </h3>
                  </a>
                  <p>
                    Reforms and incentives are attracting international
                    investors looking to diversify away from China amid its
                    intensifying rivalry with the US. But India still has a way
                    to go - on trade ties, education and infrastructure, among
                    others - if it wants to catch up to its giant northern
                    neighbour
                  </p>
                  <div className="press-card-footer">
                    <h6>Mar 23, 2024</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://www.scmp.com/week-asia/economics/article/3256423/india-comes-chinas-manufacturing-crown-supply-chains-slowly-shift?campaign=3256423&module=perpetual_scroll_0&pgtype=article"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://etimg.etb2bimg.com/photo/107918501.cms"
                    />
                  </figure>
                  <small>ET Auto</small>
                  <a
                    rel="noreferrer"
                    href="https://auto.economictimes.indiatimes.com/news/auto-technology/innovations-and-collaborations-to-shape-the-ev-future-in-last-mile-mobility/107918429"
                    target="_blank"
                  >
                    <h3>
                      Innovations and collaborations to shape the EV future in
                      last mile mobility
                    </h3>
                  </a>
                  <p>
                    A Bengaluru-based startup, Emobi, intends to enter the
                    diverse segments of electric vehicles, including last-mile
                    delivery, passenger transport, utility vehicles, and
                    personal commuting in collaboration with various prominent
                    players in the segment.
                  </p>
                  <div className="press-card-footer">
                    <h6>Feb 22, 2024</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://auto.economictimes.indiatimes.com/news/auto-technology/innovations-and-collaborations-to-shape-the-ev-future-in-last-mile-mobility/107918429"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://static.toiimg.com/thumb/msid-107152742,imgsize-37354,width-400,resizemode-4/107152742.jpg"
                    />
                  </figure>
                  <small>Times of India</small>
                  <a
                    rel="noreferrer"
                    href="https://timesofindia.indiatimes.com/business/budget/budget-2024-ev-industry-awaits-fameii-extension-reduced-gst-on-liion-batteries-and-more/articleshow/107152685.cms?val=1728"
                    target="_blank"
                  >
                    <h3>
                      Budget 2024: EV industry awaits FAME-II extension, reduced
                      GST on li-ion batteries, and more
                    </h3>
                  </a>
                  <p>
                    Especially two-wheelers, have become more popular in India
                    in the past few years. Last year, over 15 lakh EVs, spanning
                    across segments were sold. Thus, witnessing a growth of more
                    than 48 percent compared to 2022 when around 10 lakh units
                    were sold. Despite a notable overall growth in EV sales,
                    there was a temporary slowdown specifically in electric
                    two-wheeler sales around mid-2023.
                  </p>
                  <div className="press-card-footer">
                    <h6>Jan 25, 2024</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://timesofindia.indiatimes.com/business/budget/budget-2024-ev-industry-awaits-fameii-extension-reduced-gst-on-liion-batteries-and-more/articleshow/107152685.cms?val=1728"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://cdn1.expresscomputer.in/wp-content/uploads/2024/01/23171520/New-Project-2024-01-23T171315.637.jpg"
                    />
                  </figure>
                  <small>Express Computer</small>
                  <a
                    rel="noreferrer"
                    href="https://www.expresscomputer.in/exclusives/securing-the-future-of-mobility-the-role-of-cybersecurity-in-autonomous-vehicles/108472/"
                    target="_blank"
                  >
                    <h3>
                      Securing The Future Of Mobility: The Role Of Cybersecurity
                      In Autonomous Vehicles
                    </h3>
                  </a>
                  <p>
                    The auto industry has undergone an extreme transformation
                    driven by technology, particularly software innovations that
                    elevated safety, sustainability, connectivity, and the
                    overall user experience. Advancements in the field of EVs,
                    autonomous driving, and software updates have revolutionised
                    and enhanced the vehicle&amp;s performance, and improved
                    vehicle design, manufacturing, and operational services. The
                    integration of advanced technologies like human-machine
                    Interface has redefined user interaction, all accessible
                    remotely.
                  </p>
                  <div className="press-card-footer">
                    <h6>Jan 23, 2024</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://www.expresscomputer.in/exclusives/securing-the-future-of-mobility-the-role-of-cybersecurity-in-autonomous-vehicles/108472/"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://cio.eletsonline.com/wp-content/uploads/2024/01/Union-Budget-2024-25.jpg"
                    />
                  </figure>
                  <small>Elets CIO</small>
                  <a
                    rel="noreferrer"
                    href="https://cio.eletsonline.com/article/indias-economic-transformation-from-2023-budget-to-2024-prospects/71553/"
                    target="_blank"
                  >
                    <h3>
                      India&amp;s Economic Transformation from 2023 Budget to
                      2024 Prospects
                    </h3>
                  </a>
                  <p>
                    This budget is especially significant for the enterprise and
                    technology sectors, poised at the forefront of India&amp;s
                    economic growth. There is a keen anticipation for
                    initiatives that could stimulate these sectors, focusing on
                    the integration of cutting-edge technologies, attracting
                    foreign investments, and bolstering digital skills.
                    Additionally, the creation of job opportunities and
                    industry-specific insights are highly awaited. These
                    measures are vital for enhancing India&amp;s business
                    ecosystem, offering a glimpse into the strategic direction
                    the budget is expected to take.
                  </p>
                  <div className="press-card-footer">
                    <h6>Jan 23, 2024</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://cio.eletsonline.com/article/indias-economic-transformation-from-2023-budget-to-2024-prospects/71553/"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://www.financialexpress.com/wp-content/uploads/2023/11/Electric-vehicle-1.jpg?w=1024"
                    />
                  </figure>
                  <small>Financial Express</small>
                  <a
                    rel="noreferrer"
                    href="https://www.financialexpress.com/budget/budget-2024-expectations-reduction-in-taxes-extension-of-fame-scheme-and-focus-on-charging-ecosystem-says-ev-stakeholders-3363279/"
                    target="_blank"
                  >
                    <h3>
                      Budget 2024 expectations: Reduction in taxes, extension of
                      FAME scheme and focus on charging ecosystem say EV
                      stakeholders
                    </h3>
                  </a>
                  <p>
                    In 2023, a total of 15,30,326 electric vehicles were
                    registered across segments in India, compared to 10,25,134
                    units in 2022, which translates to a growth of 49.28
                    percent.
                  </p>
                  <div className="press-card-footer">
                    <h6>Jan 15, 2024</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://www.financialexpress.com/budget/budget-2024-expectations-reduction-in-taxes-extension-of-fame-scheme-and-focus-on-charging-ecosystem-says-ev-stakeholders-3363279/"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://bsmedia.business-standard.com/_media/bs/img/article/2023-06/15/full/1686848462-6388.jpg?im=FeatureCrop,size=(382,233)"
                    />
                  </figure>
                  <small>Manufacturing Today India</small>
                  <a
                    rel="noreferrer"
                    href="https://www.business-standard.com/amp/companies/news/smaller-electric-two-wheeler-players-unlikely-to-raise-prices-in-2024-123122700895_1.html"
                    target="_blank"
                  >
                    <h3>
                      Smaller electric two-wheeler players unlikely to raise
                      prices in 2024
                    </h3>
                  </a>
                  <p>
                    Smaller players manufacturing electric two-wheelers (E2Ws)
                    are unlikely to raise prices of their models initially in
                    2024 in order to ensure volumes do not drop. The prices of
                    E2Ws range from Rs 90,000 to Rs 1 lakh, the players being
                    Lohia Auto, Godawari Electric, Emobi, etc. Click here to
                    follow our WhatsApp channel As India's E2W sector gears up
                    to go past one million in 2024, manufacturers are likely to
                    maintain price stability, underpinned by strategic
                    independence from subsidies under Faster Adoption and
                    Manufacturing of Electric (and Hybrid) Vehicles II (FAME
                    II), localisation efforts, sustainability initiatives, and a
                    focus on affordability. Godawari Electric Motors, which
                    entered the market this year, exemplifies
                  </p>
                  <div className="press-card-footer">
                    <h6>Dec 27, 2023</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://www.business-standard.com/amp/companies/news/smaller-electric-two-wheeler-players-unlikely-to-raise-prices-in-2024-123122700895_1.html"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://www.manufacturingtodayindia.com/cloud/2023/12/29/Bharath-Rao.jpeg.jpg"
                    />
                  </figure>
                  <small>Manufacturing Today India</small>
                  <a
                    rel="noreferrer"
                    href="https://www.manufacturingtodayindia.com/people/emobis-ceo-on-manufacturing-trends-and-sustainability-in-2024"
                    target="_blank"
                  >
                    <h3>
                      Emobi's CEO on manufacturing trends and sustainability in
                      2024
                    </h3>
                  </a>
                  <p>
                    Envision a world where transformative technologies intersect
                    with sustainability, reshaping the very essence of
                    manufacturing. As the industry sets its sights on
                    automation, sustainability, and Industry 5.0, Emobi stands
                    at the forefront, not just adapting but innovating in the
                    face of imminent change. Let's delve into a riveting
                    discussion where innovation meets sustainable evolution, and
                    the future of manufacturing finds its visionary guide in
                    Bharath Krishna Rao.
                  </p>
                  <div className="press-card-footer">
                    <h6>Dec 29, 2023</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://www.manufacturingtodayindia.com/people/emobis-ceo-on-manufacturing-trends-and-sustainability-in-2024"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://www.analyticsinsight.net/wp-content/uploads/2023/12/How-this-EV-Startup-Integrates-AI-for-Unmatched-Success-in-India.jpg"
                    />
                  </figure>
                  <small>Analytics insights</small>
                  <a
                    rel="noreferrer"
                    href="https://www.analyticsinsight.net/how-this-indian-ev-startup-integrates-ai-for-unmatched-success/"
                    target="_blank"
                  >
                    <h3>
                      How this Indian EV Startup Integrates AI for Unmatched
                      Success
                    </h3>
                  </a>
                  <p>
                    Emobi, a prominent Indian EV startup, is driving innovation
                    in the electric vehicle sector by seamlessly integrating
                    cutting-edge technologies like IoT, 3D printing, and AI.
                    This forward-thinking approach not only enhances operational
                    efficiency but also positions Emobi at the forefront of the
                    EV industry's technological advancements. This article
                    delves into how Emobi's embrace of innovative technologies
                    is shaping the future of electric mobility.
                  </p>
                  <div className="press-card-footer">
                    <h6>Dec 21, 2023</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://www.analyticsinsight.net/how-this-indian-ev-startup-integrates-ai-for-unmatched-success/"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://img.etimg.com/thumb/msid-105820700,width-300,height-225,imgsize-27962,resizemode-75/indias-ev-market-can-touch-100-bn-revenue-by-2030-if-key-issues-addressed-report.jpg"
                    />
                  </figure>
                  <small>The Economic Times</small>
                  <a
                    rel="noreferrer"
                    href="https://economictimes.indiatimes.com/industry/renewables/domestic-ev-market-at-inflection-point-poised-for-significant-growth-report/articleshow/105820697.cms"
                    target="_blank"
                  >
                    <h3>Domestic EV market at inflection point, poised for</h3>
                  </a>
                  <p>
                    Mumbai, The domestic electric vehicle (EV) market is at an
                    inflection point and is poised for significant growth, with
                    the potential to achieve over 40 per cent penetration by
                    2030 against 5 per cent currently, a report said on
                    Thursday. The growth is expected to be driven by strong
                    adoption (45 per cent-plus) in both two and three-wheeler
                    categories while four-wheelers (cars) penetration is
                    projected to grow to over 20 per cent, India Electric
                    Vehicle Report 2023' by Bain and Company in collabo .. Read
                  </p>
                  <div className="press-card-footer">
                    <h6>Dec 07, 2023</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://economictimes.indiatimes.com/industry/renewables/domestic-ev-market-at-inflection-point-poised-for-significant-growth-report/articleshow/105820697.cms"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://thenational-the-national-prod.cdn.arcpublishing.com/resizer/Orshjd-6RxvzHFD47ixW819E_84=/800x0/filters:format(jpg):quality(70)/cloudfront-eu-central-1.images.arcpublishing.com/thenational/G4HUXFYMRTLEPT5222DA4OBY74.jpg"
                    />
                  </figure>
                  <small>The National News</small>
                  <a
                    rel="noreferrer"
                    href="https://www.thenationalnews.com/business/energy/2023/12/04/how-india-is-accelerating-its-transition-to-a-green-future-with-evs/"
                    target="_blank"
                  >
                    <h3>
                      How India is accelerating its transition to a green future
                      with EVs
                    </h3>
                  </a>
                  <p>
                    Indian companies are accelerating their efforts to tap into
                    the country's move towards electric vehicles, as the nation
                    strives to bring down carbon emissions and reduce its
                    dependence on imports of fossil fuels.
                  </p>
                  <div className="press-card-footer">
                    <h6>Dec 04, 2023</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://www.thenationalnews.com/business/energy/2023/12/04/how-india-is-accelerating-its-transition-to-a-green-future-with-evs/"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://www.financialexpress.com/wp-content/uploads/2023/10/Emobi.jpg?w=1024"
                    />
                  </figure>
                  <small>Express Mobility</small>
                  <a
                    rel="noreferrer"
                    href="https://www.financialexpress.com/business/express-mobility-emobi-partners-musashi-and-honda-power-pack-energy-for-electric-scooters-3291233/"
                    target="_blank"
                  >
                    <h3>
                      Emobi partners Musashi and Honda Power Pack Energy for
                      electric scooters
                    </h3>
                  </a>
                  <p>
                    Bengaluru-based electric vehicle start-up Emobi Manufactury
                    (Emobi) has announced a strategic partnership with Musashi
                    Seimitsu (Musashi), and Honda Power Pack Energy India (HEID)
                    for its electric scooters. The start-up has developed an
                    electric two wheeler using Musashi's high-performance
                    powertrain with Battery-as-a-Service provided through Honda
                    Power Pack Energy battery swapping solutions.
                  </p>
                  <div className="press-card-footer">
                    <h6>Oct 30, 2023</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://www.financialexpress.com/business/express-mobility-emobi-partners-musashi-and-honda-power-pack-energy-for-electric-scooters-3291233/"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://imgd.aeplcdn.com/1280x720/n/cw/ec/163721/infographics0.jpeg?isig=1&q=75"
                    />
                  </figure>
                  <small>Mobility Outlook Bureau</small>
                  <a
                    rel="noreferrer"
                    href="https://www.mobilityoutlook.com/news/emobi-partners-with-musashi-heid-to-transform-last-mile-transportation/"
                    target="_blank"
                  >
                    <h3>
                      Emobi Partners With Musashi & HEID to Transform Last-Mile
                      Transportation
                    </h3>
                  </a>
                  <p>
                    Emobi has announced a strategic partnership with Musashi
                    Seimitsu and Honda Power Pack Energy India (HEID). Designed
                    to cater to the last-mile delivery and bike taxi services,
                    the E2W competes with traditional 125CC internal combustion
                    engine (ICE) vehicles in both performance and affordability,
                    a significant milestone in India's journey towards a greener
                    and more innovative transportation future.
                  </p>
                  <div className="press-card-footer">
                    <h6>Oct 30, 2023</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://www.mobilityoutlook.com/news/emobi-partners-with-musashi-heid-to-transform-last-mile-transportation/"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://www.evmechanica.com/wp-content/uploads/2023/10/emobi-585x391.jpg"
                    />
                  </figure>
                  <small>EV Mechanica</small>
                  <a
                    rel="noreferrer"
                    href="https://www.evmechanica.com/emobi-teams-up-with-musashi-honda-power-pack-energy/"
                    target="_blank"
                  >
                    <h3>
                      Emobi Teams Up with Musashi & Honda Power Pack Energy
                    </h3>
                  </a>
                  <p>
                    The start-up has developed an electric two-wheeler using
                    Musashi’s high-performance powertrain with
                    Battery-as-a-Service provided through Honda Power Pack
                    Energy battery swapping solutions.
                  </p>
                  <div className="press-card-footer">
                    <h6>Oct 30, 2023</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://www.evmechanica.com/emobi-teams-up-with-musashi-honda-power-pack-energy/"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://etimg.etb2bimg.com/photo/104848076.cms"
                    />
                  </figure>
                  <small>ET Auto</small>
                  <a
                    rel="noreferrer"
                    href="https://auto.economictimes.indiatimes.com/amp/news/two-wheelers/emobi-develops-e2w-with-musashi-and-honda-power-pack-for-last-mile-delivery-services/104847919"
                    target="_blank"
                  >
                    <h3>
                      Emobi develops E2W with Musashi and Honda Power Pack for
                      last mile delivery services
                    </h3>
                  </a>
                  <p>
                    Emobi has developed an E2W using Musashi’s high-performance
                    powertrain with the battery swapping solutions provided
                    through Honda Power Pack Energy India. The E2W is designed
                    for performance, reliability and affordability, targeting a
                    best-in-class vehicle for the last mile delivery / bike taxi
                    segments, the company said.
                  </p>
                  <div className="press-card-footer">
                    <h6>Oct 31, 2023</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://auto.economictimes.indiatimes.com/amp/news/two-wheelers/emobi-develops-e2w-with-musashi-and-honda-power-pack-for-last-mile-delivery-services/104847919"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://etimg.etb2bimg.com/photo/104848076.cms"
                    />
                  </figure>
                  <small>wn.com</small>
                  <a
                    rel="noreferrer"
                    href="https://article.wn.com/view/2023/10/30/Emobi_partners_Musashi_and_Honda_Power_Pack_Energy_for_elect/"
                    target="_blank"
                  >
                    <h3>
                      Emobi partners Musashi and Honda Power Pack Energy for
                      electric scooters
                    </h3>
                  </a>
                  <p>
                    Bengaluru-based electric vehicle start-up Emobi Manufactury
                    (Emobi) has announced a strategic partnership with Musashi
                    Seimitsu (Musashi), and Honda Power Pack Energy India (HEID)
                    for its electric scooters. The start-up has developed an
                    electric two wheeler using Musashi’s high-performance
                    powertrain with Battery-as-a-Service
                  </p>
                  <div className="press-card-footer">
                    <h6>Oct 30, 2023</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://article.wn.com/view/2023/10/30/Emobi_partners_Musashi_and_Honda_Power_Pack_Energy_for_elect/"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://etimg.etb2bimg.com/photo/104848076.cms"
                    />
                  </figure>
                  <small>economictimes</small>
                  <a
                    rel="noreferrer"
                    href="https://energy.economictimes.indiatimes.com/news/power/emobi-develops-e2w-with-musashi-and-honda-power-pack-for-last-mile-delivery-services/104852367"
                    target="_blank"
                  >
                    <h3>
                      Emobi develops E2W with Musashi and Honda Power Pack for
                      last mile delivery
                    </h3>
                  </a>
                  <p>
                    Emobi has developed an E2W using Musashi's high-performance
                    powertrain with Battery-As-A-Service provided through HEID
                    battery swapping solutions. The E2W is designed for
                    performance, reliability and affordability, targeting a
                    best-in-class vehicle towards last mile delivery / bike taxi
                    segments, the company said.
                  </p>
                  <div className="press-card-footer">
                    <h6>Oct 31, 2023</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://energy.economictimes.indiatimes.com/news/power/emobi-develops-e2w-with-musashi-and-honda-power-pack-for-last-mile-delivery-services/104852367"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://etimg.etb2bimg.com/photo/104848076.cms"
                    />
                  </figure>
                  <small>economictimes</small>
                  <a
                    rel="noreferrer"
                    href="https://www.financialexpress.com/business/express-mobility-emobi-partners-musashi-and-honda-power-pack-energy-for-electric-scooters-3291233/"
                    target="_blank"
                  >
                    <h3>
                      Emobi partners Musashi and Honda Power Pack Energy for
                      electric scooters
                    </h3>
                  </a>
                  <p>
                    The EV is claimed to be designed to be on par with 125cc
                    equivalent ICE scooters, and is expected to be amongst the
                    best-in-class for the target segment on performance,
                    efficiency, and reliability.
                  </p>
                  <div className="press-card-footer">
                    <h6>Oct 30, 2023 </h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://www.financialexpress.com/business/express-mobility-emobi-partners-musashi-and-honda-power-pack-energy-for-electric-scooters-3291233/"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={4}>
                <div className="press-card press-card-dark">
                  <figure>
                    <img
                      alt="blogs"
                      src="https://franchiseindia.s3.ap-south-1.amazonaws.com/opp/article/english/images/1525011865.jpg"
                    />
                  </figure>
                  <small>opportunityindia</small>
                  <a
                    rel="noreferrer"
                    href="https://www.opportunityindia.com/article/emobi-musashi-and-honda-power-pack-energy-partner-for-e2w-35651"
                    target="_blank"
                  >
                    <h3>
                      Emobi, Musashi And Honda Power Pack Energy Partner For E2W
                    </h3>
                  </a>
                  <p>
                    According to it, electric scooter is designed for
                    performance, reliability and affordability, targeting a
                    best-in-class vehicle towards last-mile delivery and bike
                    taxi segments. It is claimed to be designed to be on par
                    with 125CC equivalent ICE scooters, and is expected to be
                    amongst the best-in-class for the target segment on
                    performance, efficiency, and reliability.
                  </p>
                  <div className="press-card-footer">
                    <h6>Oct 31, 2023</h6>
                    <a
                      rel="noreferrer"
                      className="link-pr"
                      href="https://www.opportunityindia.com/article/emobi-musashi-and-honda-power-pack-energy-partner-for-e2w-35651"
                      target="_blank"
                    >
                      Continue reading
                    </a>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </StaticLayout>
    </ApplicationHelmet>
  );
};

export default PressRelease;
